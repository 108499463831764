import React, { useEffect, useState } from "react"

import { graphql, PageProps } from "gatsby"
import getSearchParam from "@ecom/ui/utils/getSearchParam"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Layout from "../components/Layouts/mainPage"
import { IndexPageData } from "../interfaces/pageProps"

import { BannerText, getDynamicBannerText } from "../helpers/getDynamicBannerText"
import { getDynamicBannerUrl } from "../helpers/getDynamicBannerUrl"
import { getIndexPageData } from "../helpers/getIndexPageData"

import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/Pkw"
import { Banner as BannerBackToSchool } from "../components/Banners/BackToSchoolWithPartners"
import { Banner as RoundedDynamicBanner } from "../components/Banners/DynamicBanners/RoundedDynamicBanner"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { FormPKW } from "../components/FormPKW"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { MoreBenefitsRedisign } from "../components/MorBenefitsRedisgn"
import { FourAdvantagesPoints } from "../components/FourAdvantagesPoints"
import { OtherCards } from "../components/OtherCards"
import { BottomCta, RefinSeoBgImg } from "../components/BottomCta"
import { Partners } from "../components/PartnersNew"
import { Reviews } from "../components/Reviews"
import { Questions } from "../components/Questions"
import { NewFooter } from "../components/NewFooter"
import { QUESTIONS_FOR_GETHALVA, QUESTIONS_FOR_INDEX_PKW } from "../components/Questions/helpers"

import {
  brMobTab,
  brDeskLarge,
} from "../components/HowInstallmentWorks/howInstallmentWorks.module.scss"

import { Banner as DynamicBanner } from "../components/Banners/DynamicBanners/DefaultDynamicBanner"
import { autoPromo } from "../components/PartnersNew/helpers"

// GET HALVA ------
const subtitle = "В чем преимущества рассрочки с Халвой?"
const formSubText = (
  <p>
    Вы не платите проценты, за вас это сделает магазин. Платеж делится на равные части каждый месяц.
    К тому же, по подписке «Халва.Десятка», срок рассрочки увеличивается до 10 месяцев у каждого
    партнера!
  </p>
)

const getHalvaDescr = `
    — До 17% на остаток по карте
    <br />
    — Кэшбэк до 10%
    <br />
    — Обслуживание 0 ₽
`

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const titleFormBackToSchool = (
  <>
    Оформите карту сейчас
    <br />и участвуйте в розыгрыше
  </>
)

const partnersTitle = (
  <>
    Более 250 000
    <br className={brMobTab} /> магазинов-партнеров
  </>
)

const partnersDescr = (
  <>
    Совершайте покупки и участвуйте в розыгрыше крупных призов – путешествий,
    <br className={brDeskLarge} /> fashion и beauty боксов, техники, ювелирных изделий и АВТОМОБИЛЯ!
  </>
)

///------

const countDownTime = 1800000
const bannerDescription = `
— До 17% на остаток по карте
<br />
— Кэшбэк до 10%
<br />
— Обслуживание 0 ₽ 
`
const subTitlePartners =
  "Рассрочка 24 месяца в первый месяц использования Халвы – у всех партнеров. Со второго месяца срок рассрочки на новые покупки устанавливает партнер:"

const GIFT_TITLE = (
  <>
    Оформите Халву и получите
    <br />
    <b> месяц&nbsp;подписки&nbsp;в&nbsp;подарок</b>
  </>
)

export default function Page({ data }: PageProps<IndexPageData>) {
  const { ligalOrder, noIndex, ligalGethalva, isHiddenPageGetHalva } = getIndexPageData(data)
  const { halvaOstatok } = data.admin
  const [dynamicBannerTextObj, setDynamicBannerTextObj] = useState<BannerText>()
  const [dynamicBannerImagesObj, setDynamicBannerImagesObj] = useState<any>()

  const isMobile = useMediaQuery("(max-width:380px)")
  const bannerTitle = isMobile
    ? `<span>Карта «Халва»</span>
  <br/>
  24 месяца<br/>рассрочки
  `
    : `
  <span>Карта «Халва»</span>
  <br/>
  24 месяца&nbsp;рассрочки
`
  const getHalvaBannerTitle = isMobile
    ? `<span>Карта «Халва»</span>
<br/>
24 месяца<br/>рассрочки
`
    : `
<span>Карта «Халва»</span>
<br/>
24 месяца&nbsp;рассрочки
`
  const bannerTextUrl = getSearchParam("h")
  const bannerImageUrl = getSearchParam("bn")

  useEffect(() => {
    if (bannerImageUrl) {
      const bannerImagesData = getDynamicBannerUrl(data.allDynamicBanner, bannerImageUrl)
      if (bannerImagesData !== null) {
        setDynamicBannerImagesObj(bannerImagesData)
      }
    }
  }, [bannerImageUrl, data.allDynamicBanner])

  useEffect(() => {
    if (bannerTextUrl) {
      getDynamicBannerText(bannerTextUrl).then((result) => {
        setDynamicBannerTextObj(result)
      })
    }
  }, [bannerTextUrl])

  if (!halvaOstatok) {
    throw new Error("The halvaOstatok variable is required!!!")
  }

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  if (process.env.GATSBY_SITE_URL === "https://gethalva.ru") {
    return (
      <Layout noHeader noFooter noIndex={isHiddenPageGetHalva}>
        <NewHeader
          logoClick={false}
          redLogoWithSovcombank
          showHint={false}
          hasCTA
          additionalEventInDataLayer
        />
        {dynamicBannerImagesObj && bannerImageUrl ? (
          <DynamicBanner
            large={dynamicBannerImagesObj.dymanicBannerDesk}
            mob={dynamicBannerImagesObj.dymanicBannerMob}
            title={dynamicBannerTextObj ? dynamicBannerTextObj.title : getHalvaBannerTitle}
            description={dynamicBannerTextObj ? dynamicBannerTextObj.description : getHalvaDescr}
            orderNum="1"
          />
        ) : (
          <BannerBackToSchool orderNum="1" />
        )}
        <FourAdvantagesPoints
          withTitle
          indent={false}
          variant="pkwRedesign"
          title="Преимущества с Халвой:"
          orderNum="2"
        />
        <FormPKW
          withTimer
          dataLayerName="shortPersonalForm"
          hasBirth
          orderNum="3"
          title={titleFormBackToSchool}
          countDownTime={countDownTime}
        />
        <HowInstallmentWorks
          headTitle={
            <>
              Как принять участие
              <br className={brDeskLarge} /> в розыгрыше автомобиля
            </>
          }
          variant="autoPromo"
          orderNum="4"
        />
        <NewCalculator darkColor subtitle={subtitle} subtext={formSubText} orderNum="5" />
        <OtherCards orderNum="6" />
        <Partners
          variant="autoPromo"
          additionalPartners={autoPromo}
          title={partnersTitle}
          subTitle={partnersDescr}
          darkColor
          orderNum="7"
        />
        <MoreBenefitsRedisign
          additionalEventInDataLayer
          orderNum="8"
          hasSubtitle
          variant="pkwItemsNewHint"
          bottomBlock={false}
        />
        <BottomCta variant="pkwRedesign" title={GIFT_TITLE} BgImg={RefinSeoBgImg} orderNum="9" />
        <Reviews additionalEventInDataLayer orderNum="10" />
        <Questions questionList={QUESTIONS_FOR_GETHALVA} additionalEventInDataLayer orderNum="11" />
        <NewFooter ligal={ligalGethalva} orderNum="12" />
      </Layout>
    )
  }

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader showHint={false} hasCTA additionalEventInDataLayer countDownTime={countDownTime} />
      {dynamicBannerImagesObj && bannerImageUrl ? (
        <RoundedDynamicBanner
          large={dynamicBannerImagesObj.dymanicBannerDesk}
          mob={dynamicBannerImagesObj.dymanicBannerMob}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : bannerTitle}
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          orderNum="1"
        />
      ) : (
        <Banner orderNum="1" description={bannerDescription} />
      )}
      <FourAdvantagesPoints
        withTitle
        indent
        variant="pkwRedesign"
        title="Преимущества с Халвой:"
        orderNum="2"
      />
      <FormPKW
        dataLayerName="longPersonalForm"
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        longForm
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        orderNum="3"
        onlyPKWPage
        isGreenApprove
        navigateVerifySMS={false}
      />
      <HowInstallmentWorks
        headTitle="Как получить рассрочку на&nbsp;24&nbsp;месяца"
        variant="pkwRedesign"
        orderNum="4"
      />
      <NewCalculator additionalEventInDataLayer orderNum="5" />
      <OtherCards orderNum="6" />
      <Partners
        title="Более 250&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="7"
      />
      <MoreBenefitsRedisign
        additionalEventInDataLayer
        orderNum="8"
        hasSubtitle
        variant="pkwItemsNewHint"
        bottomBlock={false}
      />
      <BottomCta variant="pkwRedesign" title={GIFT_TITLE} BgImg={RefinSeoBgImg} orderNum="9" />
      <Reviews additionalEventInDataLayer orderNum="10" />
      <Questions questionList={QUESTIONS_FOR_INDEX_PKW} additionalEventInDataLayer orderNum="11" />
      <NewFooter ligal={ligalOrder} orderNum="12" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      order: page(url: "https://halvacard.ru/order/") {
        phones
        ligal {
          text
        }
        notIndex
      }
      getHalva: page(url: "https://gethalva.ru/") {
        ligal {
          text
        }
        notIndex
      }
      halvaOstatok: variable(name: "halvaOstatok") {
        value
      }
    }
  }
`
